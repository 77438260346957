import MDinput from '@/components/MDinput';
import { validatorEmail, validatorRequire } from '@/utils/validators';
import PickRec from '@/components/DSE/PickRec';
import FormButton from '@/components/DSE/FormButton';
import Select from '@/components/DSE/Select';

export function getFormDefinition(vue) {
  return {
    impersonateUserButton: {
      type: FormButton,
      hideOnCreate: true,
      props: {
        caption: 'common.emptyString',
        buttonText: 'common.impersonateUser',
        onclick: vue.impersonateUser
      }
    },
    id: {
      type: null,
      defaultValue: undefined
    },
    name: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 64,
        required: true
      },
      rules: [
        {
          validator: validatorRequire
        }
      ]
    },
    code: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 255,
        required: true,
        caption: 'login.code'
      },
      rules: [
        {
          validator: validatorRequire
        }
      ]
    },
    email: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 64,
        required: true,
        caption: 'common.email'
      },
      rules: [
        {
          validator: validatorRequire
        },
        {
          validator: validatorEmail
        }
      ]
    },
    avatar: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 255,
        caption: 'user.avatar'
      }
    },
    description: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 255
      }
    },
    languageID: {
      type: Select,
      defaultValue: null,
      props: {
        caption: 'navbar.language',
        required: true,
        valueField: 'id',
        labelField: 'description',
        loading: vue.$store.getters['language/isLoading'],
        options: vue.$store.getters['language/loadedItems']
      }
    },
    restaurantID: {
      type: PickRec,
      defaultValue: null,
      props: {
        caption: 'common.restaurant',
        valueField: 'id',
        labelField: 'name',
        inputField: 'name',
        maxlength: 64,
        columns: [
          { field: 'name', label: 'common.restaurant' },
          { field: 'code', label: 'common.code' }
        ],
        entity: 'Restaurant',
        required: true,
        usePagination: true,
        hideInput: false,
        suggest: true
      },
      rules: [{ validator: validatorRequire }]
    },
    robexWebshopURL: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 255,
        caption: 'user.robexWebshopURL'
      }
    }
  };
}

export function createItemFromFormData(vue, form) {
  const user = {
    ...form,
    domainID: vue.$store.getters.domainID,
    roles: []
  };
  if (form.id) {
    user['id'] = form.id;
  }
  return user;
}

export function createFormDataFromItem(vue, user) {
  return {
    ...user
  };
}
